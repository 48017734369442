<template>
    <div class="container">
        <figure class="highcharts-figure">
            <div :id="nameInLowerCase">
            </div>
        </figure>
    </div>
</template>

<script>
import Highcharts from 'highcharts';

export default {
    name: 'Histogram',
    props: [
        'title',
        'data',
        'name',
        'firstLevel',
        'secondLevel'
    ],
    emits: [
        'reloadCharts'
    ],
    mounted() {
        this.data.forEach(el => {
            el.events = {
                click: () => {
                    let levels = this.firstLevel + (this.secondLevel ? '-' + this.secondLevel : '');
                    let filter = {
                        levels: levels,
                        value: el.name
                    };
                    this.$store.dispatch('filterPeopleOnClick', filter);
                    this.$emit('reloadCharts');
                }
            }
        });
        Highcharts.chart(
            this.nameInLowerCase,
            {
                chart: {
                    type: 'column'
                },
                title: {
                    text: this.title
                },
                xAxis: {
                    type: 'category'
                },
                yAxis: {
                    title: {
                        text: 'Nombre par colonne'
                    }
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}'
                        }
                    }
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.y:.1f}</b>'
                },
                series: [
                    {
                        name: this.name,
                        colorByPoint: true,
                        data: this.data
                    }
                ]
        });
    },
    computed: {
        nameInLowerCase() {
            return 'histogram-' + this.name.toLowerCase();
        }
    }
}
</script>
